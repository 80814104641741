// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-apply-js": () => import("./../../../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faculty-and-staff-js": () => import("./../../../src/pages/faculty-and-staff.js" /* webpackChunkName: "component---src-pages-faculty-and-staff-js" */),
  "component---src-pages-horizons-js": () => import("./../../../src/pages/horizons.js" /* webpackChunkName: "component---src-pages-horizons-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-at-miss-halls-daily-life-js": () => import("./../../../src/pages/life-at-miss-halls/daily-life.js" /* webpackChunkName: "component---src-pages-life-at-miss-halls-daily-life-js" */),
  "component---src-pages-life-at-miss-halls-index-js": () => import("./../../../src/pages/life-at-miss-halls/index.js" /* webpackChunkName: "component---src-pages-life-at-miss-halls-index-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-templates-event-index-js": () => import("./../../../src/templates/Event/index.js" /* webpackChunkName: "component---src-templates-event-index-js" */),
  "component---src-templates-gallery-index-js": () => import("./../../../src/templates/Gallery/index.js" /* webpackChunkName: "component---src-templates-gallery-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/Page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-stories-category-index-js": () => import("./../../../src/templates/StoriesCategory/index.js" /* webpackChunkName: "component---src-templates-stories-category-index-js" */),
  "component---src-templates-story-index-js": () => import("./../../../src/templates/Story/index.js" /* webpackChunkName: "component---src-templates-story-index-js" */)
}

