import React from 'react';
import PropTypes from 'prop-types';

const ChevronDown = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <path d="M12.913 18.63A1.294 1.294 0 0112 19a1.32 1.32 0 01-.914-.37L1.39 9.166A1.261 1.261 0 011 8.271a1.235 1.235 0 01.378-.9A1.297 1.297 0 012.301 7a1.322 1.322 0 01.918.381l8.78 8.572 8.781-8.572A1.294 1.294 0 0121.698 7a1.322 1.322 0 01.923.37 1.26 1.26 0 01.379.9 1.236 1.236 0 01-.392.896l-9.695 9.464z" />
    </svg>
  );
};

ChevronDown.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ChevronDown.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default ChevronDown;
