import React from 'react';
import PropTypes from 'prop-types';

const Filter = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      {...otherProps}
    >
      <path d="M12 23a11 11 0 110-22 11 11 0 010 22zm0-20.167a9.166 9.166 0 100 18.333 9.166 9.166 0 000-18.333zm5.5 7.334h-11a.917.917 0 010-1.834h11a.917.917 0 010 1.834zM8.333 12h7.334a.917.917 0 010 1.833H8.333a.917.917 0 010-1.833zm1.834 3.667h3.666a.917.917 0 010 1.833h-3.666a.917.917 0 010-1.833z" />
    </svg>
  );
};

Filter.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Filter.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default Filter;
