import React, { useEffect } from 'react';
import { Link } from 'gatsby-plugin-react-intl';
import { useGoal } from 'gatsby-plugin-fathom';
import styled from 'styled-components';
import useDimensions from 'react-use-dimensions';
import get from 'lodash/get';

import colors from '@utils/colors';
import useBannerContext from '@utils/useBanner';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import { Close } from '@components/Icon';
import { Body as UnstyledBody } from '@components/type';

const Body = styled(UnstyledBody)`
  color: ${colors.N0};
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  height: 16px;
  width: 16px;
  background: transparent;
  border: 0;
  padding: 0;
  appearance: none;
  cursor: pointer;
  outline: 0;

  ${mq.gtsm`
    position: absolute;
    top: 0;
    right: 24px;
    bottom: 0;
    margin: auto 0;
  `}

  & svg {
    height: 12px;
    width: 12px;
    fill: ${colors.N0};

    ${mq.gtsm`
      height: 16px;
      width: 16px;
    `}
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 8px ${H_PADDING_MOBILE};
  z-index: 9999;
  background-color: ${colors.B500};

  & > a {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  ${mq.gtsm`
    padding-right: 0;
    text-align: center;
    justify-content: center;
  `}
`;

function Banner({ link, route, text, fathomGoalId }) {
  const { viewed, onView, setHeight } = useBannerContext();
  const [ref, { height }] = useDimensions();

  useEffect(() => {
    setHeight(height);
  }, [height, setHeight]);

  if (viewed) {
    return null;
  }
  const onClickHandler = useGoal(fathomGoalId, true);

  return (
    <Wrapper ref={ref}>
      {link ? (
        <a href={link} onClick={onClickHandler}>
          <Body sizes={['small', 'regular']}>{text}</Body>
        </a>
      ) : (
        <Link to={get(route, 'slug.current')} onClick={onClickHandler}>
          <Body sizes={['small', 'regular']}>{text}</Body>
        </Link>
      )}
      <CloseButton onClick={onView}>
        <Close />
      </CloseButton>
    </Wrapper>
  );
}

export default Banner;
