import { useState, useEffect } from 'react';

const ClientOnly = ({ children }) => {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  if (isClient) return children;

  return null;
};

export default ClientOnly;
