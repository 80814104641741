import SedgwickAveWoff2 from './Sedgwick-Ave/Sedgwick-Ave.woff2';
import SedgwickAveWoff from './Sedgwick-Ave/Sedgwick-Ave.woff';
import GTAmericaCompressedMediumWoff2 from './GT-America/GT-America-Compressed-Medium.woff2';
import GTAmericaCompressedMediumWoff from './GT-America/GT-America-Compressed-Medium.woff';
import GTAmericaCompressedMediumItalicWoff2 from './GT-America/GT-America-Compressed-Medium-Italic.woff2';
import GTAmericaCompressedMediumItalicWoff from './GT-America/GT-America-Compressed-Medium-Italic.woff';
import GTSuperTextBookWoff2 from './GT-Super/GT-Super-Text-Book.woff2';
import GTSuperTextBookWoff from './GT-Super/GT-Super-Text-Book.woff';
import GTSuperTextBookItalicWoff2 from './GT-Super/GT-Super-Text-Book-Italic.woff2';
import GTSuperTextBookItalicWoff from './GT-Super/GT-Super-Text-Book-Italic.woff';
import GTSuperTextBoldWoff2 from './GT-Super/GT-Super-Text-Bold.woff2';
import GTSuperTextBoldWoff from './GT-Super/GT-Super-Text-Bold.woff';
import GTSuperTextBoldItalicWoff2 from './GT-Super/GT-Super-Text-Bold-Italic.woff2';
import GTSuperTextBoldItalicWoff from './GT-Super/GT-Super-Text-Bold-Italic.woff';
import NotoSerifWoff2 from './Noto-Serif/NotoSerif.woff2';
import NotoSerifWoff from './Noto-Serif/NotoSerif.woff';
import NotoSerifItalicWoff2 from './Noto-Serif/NotoSerif-Italic.woff2';
import NotoSerifItalicWoff from './Noto-Serif/NotoSerif-Italic.woff';
import NotoSerifBoldWoff2 from './Noto-Serif/NotoSerif-Bold.woff2';
import NotoSerifBoldWoff from './Noto-Serif/NotoSerif-Bold.woff';
import NotoSerifBoldItalicWoff2 from './Noto-Serif/NotoSerif-BoldItalic.woff2';
import NotoSerifBoldItalicWoff from './Noto-Serif/NotoSerif-BoldItalic.woff';

import NotoSerifSCMedium from './Noto-Serif-SC/NotoSerifSC-Medium.otf';

import OswaldMediumWoff2 from './Oswald/Oswald-Medium.woff2';
import OswaldMediumWoff from './Oswald/Oswald-Medium.woff';

import GochiHand from './Gochi-Hand/GochiHand-Regular.ttf';
import LiJianMaoCao from './Liu-Jian-Mao-Cao/LiuJianMaoCao-Regular.ttf';
import Mali from './Mali/Mali-MediumItalic.ttf';

export const weights = {
  REGULAR: 400,
  BOLD: 700,
  BLACK: 900,
};

export default function(locale) {
  if (locale === 'vi') {
    return {
      HANDWRITTEN:
        'Mali, Oswald, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      SANS:
        '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      SANS_CONDENSED:
        'Oswald, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      SERIF:
        'Noto Serif, Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif',
    };
  }

  if (locale === 'zh_Hans') {
    return {
      HANDWRITTEN:
        'LiJianMaoCao, Sedgwick Ave, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      SANS:
        '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      SANS_CONDENSED:
        'GT America, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      SERIF:
        'GT Super, Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif',
      SERIF_SC:
        'Noto Serif SC Medium, GT Super, Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif'
    };
  }

  return {
    HANDWRITTEN:
      'GochiHand, Sedgwick Ave, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    SANS:
      '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    SANS_CONDENSED:
      'GT America, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    SERIF:
      'GT Super, Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif',
  };
}

// export default {
//   HANDWRITTEN:
//     'Sedgwick Ave, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
//   SANS:
//     '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
//   SANS_CONDENSED:
//     'Oswald, GT America, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
//   SERIF:
//     'Noto Serif, GT Super, Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif',
// };

export const fontPaths = {
  GochiHand,
  LiJianMaoCao,
  NotoSerifSCMedium,
  SedgwickAveWoff2,
  SedgwickAveWoff,
  GTAmericaCompressedMediumWoff2,
  GTAmericaCompressedMediumWoff,
  GTAmericaCompressedMediumItalicWoff2,
  GTAmericaCompressedMediumItalicWoff,
  GTSuperTextBookWoff2,
  GTSuperTextBookWoff,
  GTSuperTextBookItalicWoff2,
  GTSuperTextBookItalicWoff,
  GTSuperTextBoldWoff2,
  GTSuperTextBoldWoff,
  GTSuperTextBoldItalicWoff2,
  GTSuperTextBoldItalicWoff,
};

export const viFontPaths = {
  Mali,
  OswaldMediumWoff2,
  OswaldMediumWoff,
  NotoSerifWoff2,
  NotoSerifWoff,
  NotoSerifItalicWoff2,
  NotoSerifItalicWoff,
  NotoSerifBoldWoff2,
  NotoSerifBoldWoff,
  NotoSerifBoldItalicWoff2,
  NotoSerifBoldItalicWoff,
};
