export default {
  /* Blues */
  B500: '#20588D',
  B200: '#74AFE9',
  B170: '#9dc7ef',
  B150: '#bcd7f5',
  B100: '#DEEEFC',
  B50: '#f5fafe',
  B0: 'rgba(222, 238, 252, 0)',

  /* Yellows */
  Y500: '#FFDD34',
  Y200: '#F3EBCF',
  Y170: '#f6f1dd',
  Y150: '#faf8ee',
  Y100: '#FDF7E3',
  Y50: '#fefbf1',
  Y0: 'rgba(253, 247, 227, 0)',

  /* Oranges */
  O500: '#E65722',
  O200: '#F2AB90',
  O170: '#f4bba6',
  O150: '#f8d6c9',
  O100: '#FBE6DE',
  O50: '#fdf9f6',
  O0: 'rgba(251, 230, 222, 0)',

  /* Reds */
  R500: '#E72323',
  R200: '#F29090',

  /* Purples */
  P500: '#792D68',
  P200: '#C3A2BD',
  P170: '#cfb4ca',
  P150: '#e1d0de',
  P100: '#F5F0F6',
  P50: '#fcfafc',
  P0: 'rgba(245, 240, 246, 0)',

  /* Greens */
  G500: '#0B7B63',
  G200: '#8FC2B7',
  G170: '#a5cec5',
  G150: '#d2e6e2',
  G100: '#E7F2EF',
  G50: '#f7fbfa',
  G0: 'rgba(231, 242, 239, 0)',

  /* Neutrals */
  N900: '#000',
  N600: '#666',
  N500: '#333',
  N100: '#c3c3c3',
  N0: '#fff',
};
