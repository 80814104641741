import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import styled, { css, ThemeProvider } from 'styled-components';
import stringHash from 'string-hash';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import propsTransformer from '../utils/propsTransformer';
import Fonts from '@utils/atFonts';
import fonts from '@utils/fonts';
import getLocalizedData from '@utils/localize';
import StyleReset from '@utils/styleReset';
import { BannerContext } from '@utils/useBanner';
import useLocalStorage from '@utils/useLocalStorage';
import Banner from '@components/Banner';
import ClientOnly from '@components/ClientOnly';
import SEO from '@components/SEO';

const LayoutWrapper = styled.div`
  ${(props) =>
    props.hasBanner &&
    css`
      margin-top: ${props.bannerHeight}px;
    `}
`;

function Layout({ children }) {
  const rawData = useStaticQuery(graphql`
    query SanityLayoutBanner {
      sanityBanner {
        visible
        text {
          en
          vi
          zh_Hans
          _type
        }
        link
        fathomGoalId
        route {
          slug {
            current
          }
        }
      }
      sanityFooter {
        chatHeading {
          en
          vi
          zh_Hans
          _type
        }
        copyright {
          en
          vi
          zh_Hans
          _type
        }
        description {
          en
          vi
          zh_Hans
          _type
        }
        images {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        primaryLinks {
          title {
            en
            vi
            zh_Hans
            _type
          }
          slug {
            current
          }
        }
        secondaryLinks {
          title {
            en
            vi
            zh_Hans
            _type
          }
          slug {
            current
          }
        }
        enquireLink {
          isHidden
          theme
          title {
            en
            vi
            zh_Hans
          }
          Link {
            slug {
              current
            }
          }
        }
      }
      allSanityNavItem {
        edges {
          node {
            id
            order
            type
            title {
              en
              vi
              zh_Hans
              _type
            }
            description {
              en
              vi
              zh_Hans
              _type
            }
            forceDescriptionDisplay
            theme
            route {
              title {
                en
                vi
                zh_Hans
                _type
              }
              slug {
                current
              }
            }
            childRoutes {
              title {
                en
                vi
                zh_Hans
                _type
              }
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const data = getLocalizedData(rawData, intl.locale);

  const banner = data.sanityBanner;
  const [viewed, setViewed] = useLocalStorage(
    `notice-viewed-${get(banner, 'text') ? stringHash(get(banner, 'text')) : ''}`,
    false,
  );
  const [bannerHeight, setBannerHeight] = useState(0);

  const closeBanner = () => {
    setViewed(true);
  };

  const setHeight = (height) => {
    setBannerHeight(height);
  };

  const bannerContext = {
    height: bannerHeight,
    viewed: typeof window === 'undefined' ? true : viewed,
    onView: closeBanner,
    setHeight,
  };

  const navItems = data.allSanityNavItem.edges
    .map(({ node }) => node)
    .sort((itemA, itemB) => {
      if (itemA.order > itemB.order) return 1;
      if (itemA.order < itemB.order) return -1;
      return 0;
    });

  const theme = {
    locale: intl.locale,
    fonts: fonts(intl.locale),
    localFont: fonts(intl.locale),
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Fonts.EN />
        <Fonts.VI />
        <Fonts.ZH />
        <StyleReset />
        <SEO />
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css"
          />
        </Helmet>
        <BannerContext.Provider value={bannerContext}>
          <ClientOnly>
            {get(banner, 'visible') && !viewed && <Banner {...banner} />}
          </ClientOnly>
          <LayoutWrapper
            bannerHeight={bannerHeight}
            hasBanner={get(banner, 'visible') && !viewed}
          >
            {React.cloneElement(children, { banner, navItems: propsTransformer(navItems) })}
          </LayoutWrapper>
        </BannerContext.Provider>
      </ThemeProvider>
    </>
  );
}

export default Layout;
