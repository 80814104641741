import React from 'react';
import PropTypes from 'prop-types';

const Facebook = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M16 8.0489C16 3.6036 12.4183 0 8.00003 0C3.58172 0 0 3.6036 0 8.0489C0 12.0663 2.92549 15.3962 6.75 16V10.3755H4.71876V8.0489H6.75V6.27561C6.75 4.25836 7.94436 3.1441 9.77171 3.1441C10.647 3.1441 11.5625 3.3013 11.5625 3.3013V5.28208H10.5538C9.55996 5.28208 9.25 5.90252 9.25 6.53904V8.0489H11.4688L11.1141 10.3755H9.25V16C13.0746 15.3962 16 12.0663 16 8.0489Z"
        fill={color}
      />
    </svg>
  );
};

Facebook.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Facebook.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default Facebook;
