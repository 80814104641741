import React from 'react';
import PropTypes from 'prop-types';

const Check = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      {...otherProps}
    >
      <path d="M3.1 11.288l5.668 8.564L20.901 1.523c.097-.146.222-.268.368-.358a1.117 1.117 0 01.975-.093c.159.06.303.156.423.282a1.325 1.325 0 01.1 1.632L9.947 22.351c-.133.2-.31.364-.515.476a1.378 1.378 0 01-1.328 0 1.464 1.464 0 01-.514-.476L1.234 12.75a1.325 1.325 0 01.099-1.632c.12-.125.265-.221.424-.281a1.104 1.104 0 01.974.093c.146.09.271.212.368.358z" />
    </svg>
  );
};

Check.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Check.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default Check;
