/* eslint-disable quotes */

import { useIntl as useDefaultIntl } from 'gatsby-plugin-react-intl';
import format from 'date-fns/format';
import { vi, zhCN } from 'date-fns/locale';
import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';

import useTranslationContext from '@utils/useTranslations';
import enTranslations from '../intl/en.json';

export const dateLocales = {
  vi,
  zh_Hans: zhCN,
};

export function useLocalFormat() {
  const availableTranslations = useTranslationContext();
  const intl = useIntl();

  if (intl.locale === 'en' || !availableTranslations || !availableTranslations[intl.locale]) {
    return (date, dateFormat) => format(date, dateFormat);
  }

  const localizedFormats = {
    vi: {
      'MMMM d, yyyy': 'd MMMM, yyyy',
      'MMMM yyyy': 'MMMM yyyy',
      'MMMM do, yyyy': 'd MMMM, yyyy',
      'LLL d, yyyy': 'd LLL, yyyy',
      "h:mm a 'on' MMMM d, yyyy": 'h:mm a d MMMM, yyyy',
    },
    zh_Hans: {
      'MMMM d, yyyy': 'yyyy MMMM d',
      'MMMM yyyy': 'yyyy MMMM',
      'MMMM do, yyyy': 'yyyy MMMMM d',
      'LLL d, yyyy': 'yyyy LLL d',
      "h:mm a 'on' MMMM d, yyyy": 'yyyy MMMM d h:mm a',
    },
  };

  return (date, dateFormat) => format(date, localizedFormats[intl.locale][dateFormat], {
    locale: dateLocales[intl.locale],
  });
}

export function useIntl() {
  const availableTranslations = useTranslationContext();
  const intl = useDefaultIntl();

  if (!availableTranslations) {
    return {
      ...intl,
      locale: intl.locale === 'zh-Hans' ? 'zh_Hans' : intl.locale,
    };
  }

  if (!availableTranslations[intl.locale === 'zh-Hans' ? 'zh_Hans' : intl.locale]) {
    return {
      ...intl,
      formatMessage: ({ id }) => get(enTranslations, id),
    };
  }

  return {
    ...intl,
    locale: intl.locale === 'zh-Hans' ? 'zh_Hans' : intl.locale,
  };
}

export default function getLocalizedData(data, lang) {
  function localize(value, languages) {
    if (Array.isArray(value)) {
      return value.map((v) => localize(v, languages));
    } else if (isPlainObject(value)) {
      if (/^locale[A-Z]/.test(value._type)) {
        const language = languages.find((lang) => value[lang]);
        return value[language];
      }

      return Object.keys(value).reduce((result, key) => {
        result[key] = localize(value[key], languages);
        return result;
      }, {});
    }
    return value;
  }

  return localize(data, [lang, 'en']);
}
