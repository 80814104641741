import React from 'react';
import PropTypes from 'prop-types';

const ArrowLeft = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...otherProps}
    >
      <path d="M7.222 17c-1.603-2.356-4.3-2.588-6.222-4.481C2.85 11.699 6.527 9.857 7.695 8M23 12.217c-5.622 1.052-10.81.052-16.483.441L23 12.217z" stroke={color} strokeWidth="1.6" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

ArrowLeft.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ArrowLeft.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default ArrowLeft;
