import React from 'react';
import PropTypes from 'prop-types';

const Info = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      {...otherProps}
    >
      <path d="M12 23a11 11 0 110-22 11 11 0 010 22zm0-20.167a9.166 9.166 0 100 18.333 9.166 9.166 0 000-18.333zm.688 5.5a1.503 1.503 0 01-1.605-1.375 1.503 1.503 0 011.605-1.375 1.503 1.503 0 011.604 1.375 1.503 1.503 0 01-1.604 1.375zm.65 3.1l-1.276 4.817a.958.958 0 00.57 1.135.458.458 0 01.287.425v.148a.458.458 0 01-.458.459h-1.37a1.377 1.377 0 01-1.338-1.725l1.276-4.817a.958.958 0 00-.57-1.135.458.458 0 01-.287-.425v-.148a.459.459 0 01.458-.459H12a1.377 1.377 0 011.339 1.725z" />
    </svg>
  );
};

Info.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Info.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default Info;
