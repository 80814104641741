import React from 'react';
import PropTypes from 'prop-types';

const Youtube = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M15.666 3.72665C15.5732 3.39437 15.3963 3.09163 15.1524 2.84769C14.9085 2.60375 14.6057 2.42687 14.2734 2.33413C13.0263 2.00001 8.00712 2.00001 8.00712 2.00001C8.00712 2.00001 3.00059 1.99334 1.74079 2.33413C1.40851 2.42687 1.10577 2.60375 0.861831 2.84769C0.617893 3.09163 0.441008 3.39437 0.348273 3.72665C0.111866 5.00347 -0.00468405 6.29958 0.000144048 7.59809C-0.00377132 8.8917 0.112775 10.1829 0.348273 11.4549C0.441008 11.7871 0.617893 12.0899 0.861831 12.3338C1.10577 12.5778 1.40851 12.7546 1.74079 12.8474C2.98659 13.1822 8.00712 13.1822 8.00712 13.1822C8.00712 13.1822 13.013 13.1822 14.2734 12.8474C14.6057 12.7546 14.9085 12.5778 15.1524 12.3338C15.3963 12.0899 15.5732 11.7871 15.666 11.4549C15.8965 10.1824 16.0082 8.89124 15.9994 7.59809C16.0091 6.30004 15.8975 5.00393 15.666 3.72665V3.72665ZM6.40519 9.99298V5.19586L10.5827 7.59809L6.40519 9.99298Z"
        fill={color}
      />
    </svg>
  );
};

Youtube.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Youtube.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default Youtube;
