import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from '@utils/localize';

import getLocalizedData from '@utils/localize';

function SEO({ description, image: metaImage, meta, title, noIndex, keywords }) {
  const intl = useIntl();

  const rawData = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        sanitySiteInfo {
          title {
            en
            vi
            zh_Hans
            _type
          }
          description {
            en
            vi
            zh_Hans
            _type
          }
          keywords
        }
      }
    `,
  );
  const data = getLocalizedData(rawData, intl.locale);
  const { sanitySiteInfo } = data;
  const fullTitle = `${title} | ${sanitySiteInfo.title}`;
  const metaKeywords = (keywords || sanitySiteInfo.keywords).join(', ');
  const metaDescription = description || sanitySiteInfo.description;

  const metaArray = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      name: 'keywords',
      content: metaKeywords,
    },
    {
      property: 'og:title',
      content: fullTitle,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:title',
      content: fullTitle,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
    {
      name: 'twitter:site',
      content: '@misshallsschool',
    },
    {
      name: 'twitter:creator',
      content: '@misshallsschool',
    },
  ]
    .concat(
      metaImage
        ? [
          {
            property: 'og:image',
            content: metaImage.src,
          },
          {
            property: 'og:image:width',
            content: metaImage.width,
          },
          {
            property: 'og:image:height',
            content: metaImage.height,
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
        ]
        : [
          {
            name: 'twitter:card',
            content: 'summary',
          },
        ],
    )
    .concat(
      noIndex ? [
        {
          name: 'robots',
          content: 'noindex',
        },
      ]: [],
    )
    .concat(meta);

  return (
    <Helmet
      htmlAttributes={{ lang: intl.locale }}
      title={title || sanitySiteInfo.title}
      titleTemplate={title ? `%s | ${sanitySiteInfo.title}` : '%s'}
    >
      <meta content="text/html; charset=UTF-8" name="Content-Type" />
      <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
      <link rel="manifest" href="/favicons/site.webmanifest" />
      <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#20588d" />
      <link rel="shortcut icon" href="/favicons/favicon.ico" />
      <meta name="msapplication-TileColor" content="#deeefc" />
      <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
      <meta name="theme-color" content="#ffffff" />
      {metaArray.map(item => <meta key={item.name + item.value} {...item} />)};
    </Helmet>
  );
}

SEO.defaultProps = {
  meta: [],
  description: '',
  noIndex: false,
};

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  noIndex: PropTypes.bool,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
