import React from 'react';
import PropTypes from 'prop-types';

const Close = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <path d="M22.586 22.597a1.374 1.374 0 01-1.944 0l-8.654-8.649-8.646 8.642a1.375 1.375 0 01-1.944-1.943l8.646-8.641-8.645-8.64a1.373 1.373 0 011.944-1.943l8.645 8.64 8.653-8.648a1.376 1.376 0 111.943 1.943l-8.652 8.648 8.654 8.649a1.373 1.373 0 010 1.942z" />
    </svg>
  );
};

Close.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Close.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default Close;
