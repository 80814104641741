import React from 'react';
import PropTypes from 'prop-types';

const Twitter = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M15.9687 2.5486C15.3787 2.80792 14.7487 2.98458 14.0854 3.06525C14.7614 2.65793 15.2814 2.01596 15.5274 1.24999C14.8934 1.61997 14.1907 1.8893 13.4428 2.03929C12.8455 1.39998 11.9942 1 11.0489 1C9.23761 1 7.76901 2.46861 7.76901 4.27786C7.76901 4.53785 7.79901 4.78784 7.85367 5.02717C5.12712 4.8985 2.71055 3.58923 1.09329 1.60997C0.808633 2.09129 0.649306 2.6506 0.649306 3.25991C0.649306 4.39986 1.22928 5.40182 2.10791 5.99046C1.56993 5.97313 1.06396 5.82513 0.622641 5.57981V5.62047C0.622641 7.21041 1.75126 8.53635 3.2532 8.83834C2.97788 8.91234 2.68722 8.95234 2.38923 8.95234C2.17991 8.95234 1.97925 8.93234 1.77859 8.895C2.19924 10.197 3.40852 11.1462 4.8478 11.1729C3.72784 12.0522 2.30857 12.5762 0.779967 12.5762C0.519978 12.5762 0.260656 12.5609 0 12.5315C1.45927 13.4608 3.17853 14.0041 5.03779 14.0041C11.0735 14.0041 14.3701 9.007 14.3701 4.68051C14.3701 4.54119 14.3701 4.40052 14.3601 4.26053C15.0007 3.80122 15.56 3.22057 16 2.56194L15.9687 2.5486Z"
        fill={color}
      />
    </svg>
  );
};

Twitter.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Twitter.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default Twitter;
