import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';

export default createGlobalStyle`
  ${reset}
  html {
    height: 100%;
    box-sizing: border-box;
  }
  body {
    color: ${colors.N900};
    font-family: ${props => fonts(props.theme.locale).STANDARD};
    font-size: 15px;
    font-weight: ${weights.LIGHT};
    line-height: 1.6;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  a {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => fonts(props.theme.locale).CONDENSED};
  }
`;
