import React from 'react';
import PropTypes from 'prop-types';

const Menu = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <path d="M2.158 2.725h19.684c.307 0 .602.124.819.345a1.19 1.19 0 010 1.667 1.148 1.148 0 01-.819.345H2.158c-.307 0-.602-.124-.819-.345a1.19 1.19 0 010-1.667c.217-.22.512-.345.819-.345zm0 8.25h19.684c.307 0 .602.124.819.345a1.19 1.19 0 010 1.667 1.148 1.148 0 01-.819.345H2.158c-.307 0-.602-.124-.819-.345a1.19 1.19 0 010-1.667c.217-.22.512-.345.819-.345zm0 8.25h19.684c.307 0 .602.124.819.345a1.19 1.19 0 010 1.667 1.148 1.148 0 01-.819.345H2.158c-.307 0-.602-.124-.819-.345a1.19 1.19 0 010-1.667c.217-.22.512-.345.819-.345z" />
    </svg>
  );
};

Menu.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Menu.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default Menu;
