import React from 'react';
import PropTypes from 'prop-types';

const ArrowRight = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...otherProps}
    >
      <path d="M16.778 17c1.603-2.356 4.3-2.588 6.222-4.481-1.85-.82-5.527-2.662-6.695-4.519M1 12.217c5.622 1.052 10.81.052 16.483.441L1 12.217z" stroke={color} strokeWidth="1.6" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

ArrowRight.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ArrowRight.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default ArrowRight;
