import { createGlobalStyle } from 'styled-components';
import { fontPaths, viFontPaths } from '@utils/fonts';

export default {
  EN: createGlobalStyle`
  @font-face {
    font-family: 'GochiHand';
    font-display: block;
    src:
      url(${fontPaths.GochiHand});
  }

  @font-face {
    font-family: 'Sedgwick Ave';
    font-display: block;
    src:
      url(${fontPaths.SedgwickAveWoff2}),
      url(${fontPaths.SedgwickAveWoff});
  }

  @font-face {
    font-family: 'GT America';
    font-display: block;
    font-weight: 700;
    src:
      url(${fontPaths.GTAmericaCompressedMediumWoff2}),
      url(${fontPaths.GTAmericaCompressedMediumWoff});
  }

  @font-face {
    font-family: 'GT America';
    font-display: block;
    font-weight: 700;
    font-style: italic;
    src:
      url(${fontPaths.GTAmericaCompressedMediumItalicWoff2}),
      url(${fontPaths.GTAmericaCompressedMediumItalicWoff});
  }

  @font-face {
    font-family: 'GT Super';
    font-display: block;
    src:
      url(${fontPaths.GTSuperTextBookWoff2}),
      url(${fontPaths.GTSuperTextBookWoff});
  }

  @font-face {
    font-family: 'GT Super';
    font-display: block;
    font-style: italic;
    src:
      url(${fontPaths.GTSuperTextBookItalicWoff2}),
      url(${fontPaths.GTSuperTextBookItalicWoff});
  }

  @font-face {
    font-family: 'GT Super';
    font-display: block;
    font-weight: 700;
    src:
      url(${fontPaths.GTSuperTextBoldWoff2}),
      url(${fontPaths.GTSuperTextBoldWoff});
  }

  @font-face {
    font-family: 'GT Super';
    font-display: block;
    font-style: italic;
    font-weight: 700;
    src:
      url(${fontPaths.GTSuperTextBoldItalicWoff2}),
      url(${fontPaths.GTSuperTextBoldItalicWoff});
  }
  `,
  ZH: createGlobalStyle`
  @font-face {
    font-family: 'LiJianMaoCao';
    font-display: block;
    src:
      url(${fontPaths.LiJianMaoCao});
  }

  @font-face {
    font-family: 'Sedgwick Ave';
    font-display: block;
    src:
      url(${fontPaths.SedgwickAveWoff2}),
      url(${fontPaths.SedgwickAveWoff});
  }

  @font-face {
    font-family: 'GT America';
    font-display: block;
    font-weight: 700;
    src:
      url(${fontPaths.GTAmericaCompressedMediumWoff2}),
      url(${fontPaths.GTAmericaCompressedMediumWoff});
  }

  @font-face {
    font-family: 'GT America';
    font-display: block;
    font-weight: 700;
    font-style: italic;
    src:
      url(${fontPaths.GTAmericaCompressedMediumItalicWoff2}),
      url(${fontPaths.GTAmericaCompressedMediumItalicWoff});
  }

  @font-face {
    font-family: 'GT Super';
    font-display: block;
    src:
      url(${fontPaths.GTSuperTextBookWoff2}),
      url(${fontPaths.GTSuperTextBookWoff});
  }

  @font-face {
    font-family: 'Noto Serif SC Medium';
    font-display: block;
    font-weight: 500;
    src:
      url(${fontPaths.NotoSerifSCMedium});
  }

  @font-face {
    font-family: 'GT Super';
    font-display: block;
    font-style: italic;
    src:
      url(${fontPaths.GTSuperTextBookItalicWoff2}),
      url(${fontPaths.GTSuperTextBookItalicWoff});
  }

  @font-face {
    font-family: 'GT Super';
    font-display: block;
    font-weight: 700;
    src:
      url(${fontPaths.GTSuperTextBoldWoff2}),
      url(${fontPaths.GTSuperTextBoldWoff});
  }

  @font-face {
    font-family: 'GT Super';
    font-display: block;
    font-style: italic;
    font-weight: 700;
    src:
      url(${fontPaths.GTSuperTextBoldItalicWoff2}),
      url(${fontPaths.GTSuperTextBoldItalicWoff});
  }
  `,
  VI: createGlobalStyle`
  @font-face {
    font-family: 'Mali';
    font-display: block;
    font-weight: 700;
    src:
      url(${viFontPaths.Mali});
  }

  @font-face {
    font-family: 'Oswald';
    font-display: block;
    font-weight: 700;
    src:
      url(${viFontPaths.OswaldMediumWoff2}),
      url(${viFontPaths.OswaldMediumWoff});
  }

  @font-face {
    font-family: 'Oswald';
    font-display: block;
    font-weight: 700;
    font-style: italic;
    src:
      url(${viFontPaths.OswaldMediumItalicWoff2}),
      url(${viFontPaths.OswaldMediumItalicWoff});
  }
  @font-face {
    font-family: 'Noto Serif';
    font-display: block;
    src:
      url(${viFontPaths.NotoSerifWoff2}),
      url(${viFontPaths.NotoSerifWoff});
  }

  @font-face {
    font-family: 'Noto Serif';
    font-display: block;
    font-style: italic;
    src:
      url(${viFontPaths.NotoSerifItalicWoff2}),
      url(${viFontPaths.NotoSerifItalicWoff});
  }

  @font-face {
    font-family: 'Noto Serif';
    font-display: block;
    font-weight: 700;
    src:
      url(${viFontPaths.NotoSerifBoldWoff2}),
      url(${viFontPaths.NotoSerifBoldWoff});
  }

  @font-face {
    font-family: 'Noto Serif';
    font-display: block;
    font-style: italic;
    font-weight: 700;
    src:
      url(${viFontPaths.NotoSerifBoldItalicWoff2}),
      url(${viFontPaths.NotoSerifBoldItalicWoff});
  }
  `,
};
